<template>
  <v-card class="my-5">
    <v-toolbar color="green darken-2" dark>
      <v-toolbar-title class="row justify-center">Условия оплаты курьера</v-toolbar-title>
    </v-toolbar>
    <v-row no-gutters class="ma-3 justify-center justify-lg-end justify-md-end justify-sm-end">
      <newrule />
    </v-row>
    <v-row dense class="hidden-xs-only">
      <v-col cols="3" class="col col-lg-2 col-md-2 sm-2 row no-gutters justify-center">
        <span class="caption">Тип транспорта</span>
      </v-col>
      <v-col class="row no-gutters justify-center">
        <span class="caption">Смена (в часах)</span>
      </v-col>
      <v-col class="row no-gutters justify-center">
        <span class="caption">Оплата за час</span>
      </v-col>
      <v-col class="row no-gutters justify-center">
        <span class="caption">Оплата</span>
      </v-col>
      <v-col class="row no-gutters justify-center">
        <span class="caption">Бензин</span>
      </v-col>
      <v-col class="row no-gutters justify-center">
        <span class="caption">Бонус после</span>
      </v-col>
      <v-col class="row no-gutters justify-center">
        <span class="caption">Бонус (в сомах)</span>
      </v-col>
      <v-col cols="3" class="col col-lg-2 col-md-2 sm-2 row no-gutters justify-center">
        <span class="caption">Редактировать</span>
      </v-col>
    </v-row>
    <v-row no-gutters class="grey lighten-4 mb-1 mx-1" v-for="item in list" :key="item.id">
      <v-col cols="3" class="col col-lg-2 col-md-2 sm-2 row no-gutters justify-center text-body-2 d-flex align-center">
        <div v-for="vehicle in vehicleTypes" :key="vehicle.id">
          <span v-if="item.vehicle_type_id === vehicle.id">{{ vehicle.name }}</span>
        </div>
      </v-col>
      <v-col class="row no-gutters justify-space-around d-flex align-center text-body-2">
        <span>{{ item.hours_count }}</span>
      </v-col>
      <v-col class="row no-gutters justify-space-around d-flex align-center text-body-2">
        <span>{{ item.hours_payment }}</span>
      </v-col>
      <v-col class="row no-gutters justify-space-around d-flex align-center text-body-2">
        <span>{{ item.hours_count * item.hours_payment }}</span>
      </v-col>
      <v-col class="row no-gutters justify-space-around d-flex align-center text-body-2">
        <span>{{ item.fuel }}</span>
      </v-col>
      <v-col class="row no-gutters justify-space-around d-flex align-center text-body-2">
        <span>{{ item.bonus_min_count }}</span>
      </v-col>
      <v-col class="row no-gutters justify-space-around d-flex align-center text-body-2">
        <span>{{ item.bonus_payment }}</span>
      </v-col>
      <v-col cols="3" class="col col-lg-2 col-md-2 sm-2 row no-gutters justify-space-around d-flex align-center">
        <edit :rule="item"/>
        <v-btn x-small fab @click="removeRule(item.id)">
          <v-icon color="error">mdi-delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>

import axios from "../../../services/axios";
import edit from "./edit.vue";
import newrule from "./new.vue";

export default {
  components: { edit, newrule },
  data() {
    return {
      list: [],
      vehicleTypes: []
    };
  },

  beforeMount() {
    this.fetch();
    this.fetchVehicle();
  },

  methods: {
    fetch() {
      axios.send({
        url: "/drivers/rules",
        method: "GET"
      }).then(resp => {
        this.list = resp.data;
      })
    },

    fetchVehicle() {
      axios.send({
        url: "/vehicle/types",
        method: "GET"
      }).then(resp => {
        this.vehicleTypes = resp.data;
      });
    },

    removeRule(id) {
      this.$confirm(
          "Вы точно хотите удалить правило",
          "Внимание",
          "question"
      ).then(() => {
        axios({
          url: "/drivers/rules/" + id,
          method: "DELETE",
        }).then(() => {
          this.fetch();
        });
      });
    },
  }
};
</script>

<style scoped>
.vertical {
  text-orientation: mixed;
  writing-mode: vertical-rl;
}
</style>
