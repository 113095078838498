<template>
  <v-dialog v-model="dialog" :max-width="$vuetify.breakpoint.xs ? 374 : 400">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" class="mr-6" rounded color="primary">
        <span class="font-weight-bold">Создать</span>
        <v-icon right>mdi-plus</v-icon>
      </v-btn>
    </template>
    <v-card shaped class="pa-3">
      <v-toolbar elevation="5" class="row no-gutters justify-center" dense color="primary">
        <v-toolbar-title>Создать</v-toolbar-title>
      </v-toolbar>
      <v-row class="ma-3">
        <v-col cols="12">
          <v-autocomplete v-model="selectedVehicle"
                          :items="vehicleTypes"
                          item-text="name"
                          item-value="id"
                          return-object
                          dense
                          outlined
                          rounded
                          clearable
                          hide-details
                          label="Тип транспорта"/>
        </v-col>
        <v-col cols="12">
          <v-text-field v-model="item.hours_count"
                        dense
                        outlined
                        rounded
                        hide-details
                        label="Смена (в часах)"/>
        </v-col>
        <v-col cols="12">
          <v-text-field v-model="item.hours_payment"
                        dense
                        outlined
                        rounded
                        hide-details
                        label="Гар. оплата за час"/>
        </v-col>
        <v-col cols="12">
          <v-text-field v-model="item.fuel"
                        dense
                        outlined
                        rounded
                        hide-details
                        label="Бензин (в сомах)"/>
        </v-col>
        <v-col cols="12">
          <v-text-field v-model="item.bonus_min_count"
                        dense
                        outlined
                        rounded
                        hide-details
                        label="Бонус после (кол. заказов)"/>
        </v-col>
        <v-col cols="12">
          <v-text-field v-model="item.bonus_payment"
                        dense
                        outlined
                        rounded
                        hide-details
                        label="Стоимость бонуса"/>
        </v-col>
      </v-row>
      <v-row class="justify-space-around pb-3">
        <v-btn outlined elevation="3" rounded color="error" @click="dialog = false">Отменить</v-btn>
        <v-btn outlined elevation="3" rounded color="primary" @click="save">Сохранить</v-btn>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>

import axios from "@/services/axios";
import {uuid} from "uuidv4";

export default {
  data() {
    return {
      selectedVehicle: {},
      dialog: false,
      vehicleTypes: [],
      item: {
        id: uuid.Nil,
        vehicle_type_id: uuid.Nil,
        hours_count: 0,
        hours_payment: 0,
        fuel: 0,
        bonus_min_count: 0,
        bonus_payment: 0
      }
    }
  },

  mounted() {
    this.fetchVehicle();
  },

  methods: {
    fetchVehicle() {
      axios.send({
        url: "/vehicle/types",
        method: "GET"
      }).then(resp => {
        this.vehicleTypes = resp.data;
      });
    },
    save() {
      this.item.hours_count = parseInt(this.item.hours_count);
      this.item.hours_payment = parseInt(this.item.hours_payment);
      this.item.fuel = parseInt(this.item.fuel);
      this.item.bonus_min_count = parseInt(this.item.bonus_min_count);
      this.item.bonus_payment = parseInt(this.item.bonus_payment);
      this.item.vehicle_type_id = this.selectedVehicle.id;
      axios.send({
        url: "/drivers/rules",
        method: "POST",
        data: this.item,
      }).then(() => {
        this.$router.go();
        this.dialog = false;
      });
    }
  }
};
</script>
