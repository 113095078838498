<template>
  <v-dialog v-model="dialog" :max-width="$vuetify.breakpoint.xs ? 374 : 400">
    <template v-slot:activator="{ on, attrs }">
      <v-btn x-small fab v-bind="attrs" v-on="on">
        <v-icon color="primary">mdi-pencil</v-icon>
      </v-btn>
    </template>
    <v-card shaped class="pa-3">
      <v-toolbar dense color="primary" elevation="5" class="row no-gutters justify-center">
        <v-toolbar-title>Редактировать</v-toolbar-title>
      </v-toolbar>
      <v-row class="ma-3">
        <v-col cols="12" class="row no-gutters justify-center font-weight-bold">
          <div v-for="vehicle in vehicleTypes" :key="vehicle.id">
            <span v-if="item.vehicle_type_id === vehicle.id">{{ vehicle.name }}</span>
          </div>
        </v-col>
        <v-col cols="12">
          <v-text-field v-model="item.hours_count"
                        dense
                        outlined
                        rounded
                        hide-details
                        label="Смена (в часах)"/>
        </v-col>
        <v-col cols="12">
          <v-text-field v-model="item.hours_payment"
                        dense
                        outlined
                        rounded
                        hide-details
                        label="Гар. оплата за час"/>
        </v-col>
        <v-col cols="12">
          <v-text-field v-model="item.fuel"
                        dense
                        outlined
                        rounded
                        hide-details
                        label="Бензин (в сомах)"/>
        </v-col>
        <v-col cols="12">
          <v-text-field v-model="item.bonus_min_count"
                        dense
                        outlined
                        rounded
                        hide-details
                        label="Бонус после (кол. заказов)"/>
        </v-col>
        <v-col cols="12">
          <v-text-field v-model="item.bonus_payment"
                        dense
                        outlined
                        rounded
                        hide-details
                        label="Стоимость бонуса"/>
        </v-col>
      </v-row>
      <v-row class="justify-space-around pb-3">
        <v-btn outlined elevation="3" rounded color="error" @click="dialog = false">Отменить</v-btn>
        <v-btn outlined elevation="3" rounded color="primary" @click="save">Сохранить</v-btn>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>

import axios from "@/services/axios";
import {uuid} from "uuidv4";

export default {
  props: {
    rule: Object
  },
  data() {
    return {
      selectedVehicle: {},
      dialog: false,
      vehicleTypes: [],
      item: {
        id: uuid.Nil,
        hours_count: 0,
        hours_payment: 0,
        fuel: 0,
        bonus_min_count: 0,
        bonus_payment: 0,
        vehicle_type_id: uuid.Nil
      }
    }
  },

  mounted() {
    this.fetchVehicle();

    this.item.id = this.rule.id;
    this.item.hours_count = this.rule.hours_count;
    this.item.hours_payment = this.rule.hours_payment;
    this.item.fuel = this.rule.fuel;
    this.item.bonus_min_count = this.rule.bonus_min_count;
    this.item.bonus_payment = this.rule.bonus_payment;
    this.item.vehicle_type_id = this.rule.vehicle_type_id;
    this.selectedVehicle.id = this.item.vehicle_type_id;
  },

  methods: {
    fetchVehicle() {
      axios.send({
        url: "/vehicle/types",
        method: "GET"
      }).then(resp => {
        this.vehicleTypes = resp.data;
      });
    },
    save() {
      this.item.fuel = parseInt(this.item.fuel);
      this.item.hours_count = parseInt(this.item.hours_count);
      this.item.hours_payment = parseInt(this.item.hours_payment);
      this.item.bonus_min_count = parseInt(this.item.bonus_min_count);
      this.item.bonus_payment = parseInt(this.item.bonus_payment);
      axios.send({
        url: "/drivers/rules",
        method: "PUT",
        data: this.item,
      }).then(() => {
        this.$router.go();
        this.dialog = false;
      });
    }
  }
};
</script>
